import Network from '@/services/network'
import { ref } from 'vue'

export default function handleSidebar() {
    const network = new Network;
    const loading = ref(false)

    const fetchSidebar = () => {
      return network.api('get', '/get/sidebar');
    }
    
    const fetchCompanyModuleByFirstMenu = (company_id, module_id = '') => {
        network.api('get', `/get/sidebar?company_id=${company_id}&module_id=${module_id}`).then(res => {
            if (res.status) {
                if (res.data.length > 0) {
                    let first_menu = res.data[0];
                    let module_id = res.module_id
                    let menu_id = first_menu.id
                    window.location.href = `/company/${company_id}/module/${module_id}/menu/${menu_id}`
                } else {
                    window.location.href = `/company/${company_id}/module/${module_id}`
                }
            }
        })
    }

    return {
        fetchSidebar,
        fetchCompanyModuleByFirstMenu,
        loading
    }
}